body {
  margin: 0;
}

[data-amplify-authenticator] {
  height: 100vh;
  width: 100vw;
  background-color: #15202b;

  --amplify-components-authenticator-router-background-color: #102733;
  --amplify-components-authenticator-router-border-color: #102733;

  --amplify-components-authenticator-state-inactive-background-color: #22303d;
  --amplify-components-tabs-item-color: #fff;

  --amplify-components-tabs-item-active-color: #1da1f2;
  --amplify-components-tabs-item-focus-color: #1da1f2;
  --amplify-components-tabs-item-hover-color: #1da1f2;
  --amplify-components-tabs-item-active-color: #1da1f2;

  --amplify-components-tabs-item-active-border-color: #1da1f2;
  --amplify-components-tabs-item-border-color: #293a49;

  --amplify-components-button-primary-background-color: #1da1f2;
  --amplify-components-button-primary-hover-background-color: #0071b8;
  --amplify-components-button-primary-active-background-color: #00568b;

  --amplify-components-button-hover-background-color: #22303d;

  --amplify-components-textfield-color: #fff;

  --amplify-components-passwordfield-button-active-background-color: #22303d;
  --amplify-components-passwordfield-button-disabled-background-color: #22303d;
  --amplify-components-passwordfield-button-focus-background-color: #22303d;
  --amplify-components-passwordfield-button-hover-background-color: #293a49;

  --amplify-components-text-error-color: #ff1c1c;
  --amplify-components-fieldcontrol-error-border-color: #ff1c1c;
  --amplify-components-button-primary-disabled-background-color: #0071b8;

  --amplify-components-textfield-border-color: #0071b8;
  --amplify-components-button-border-color: #0071b8;
  --amplify-components-textfield-focus-border-color: #1da1f2;

  --amplify-components-button-color: #fff;
  --amplify-components-passwordfield-button-color: #fff;
  --amplify-components-passwordfield-button-hover-color: #fff;
  --amplify-components-button-hover-color: #fff;
  --amplify-components-button-focus-color: #fff;
  --amplify-components-passwordfield-button-focus-color: #fff;

  --amplify-components-button-hover-background-color: #102733;
  --amplify-components-passwordfield-button-hover-background-color: #102733;

  --amplify-components-button-hover-border-color: #0071b8;
  --amplify-components-passwordfield-button-hover-border-color: #0071b8;
  --amplify-components-passwordfield-button-focus-border-color: #0071b8;

  --amplify-components-heading-color: #fff;
  --amplify-components-text-color: #fff;
}
